import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if @collapsable}}
  <DButton
    @title="sidebar.toggle_section"
    @action={{@toggleSectionDisplay}}
    aria-controls={{@sidebarSectionContentID}}
    aria-expanded={{if @isExpanded "true" "false"}}
    class="sidebar-section-header sidebar-section-header-collapsable btn-transparent"
  >
    {{yield}}
  </DButton>
{{else}}
  <span class="sidebar-section-header">
    {{yield}}
  </span>
{{/if}}
*/
{
  "id": "AJHQFmQa",
  "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,\"aria-controls\",[30,2]],[16,\"aria-expanded\",[52,[30,3],\"true\",\"false\"]],[24,0,\"sidebar-section-header sidebar-section-header-collapsable btn-transparent\"]],[[\"@title\",\"@action\"],[\"sidebar.toggle_section\",[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[14,0,\"sidebar-section-header\"],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@collapsable\",\"@sidebarSectionContentID\",\"@isExpanded\",\"@toggleSectionDisplay\",\"&default\"],false,[\"if\",\"d-button\",\"yield\"]]",
  "moduleName": "discourse/components/sidebar/section-header.hbs",
  "isStrictMode": false
});