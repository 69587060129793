import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Sidebar::SectionLink
  @badgeText={{@sectionLink.badgeText}}
  @content={{replace-emoji @sectionLink.text}}
  @currentWhen={{@sectionLink.currentWhen}}
  @href={{or @sectionLink.href @sectionLink.value}}
  @linkName={{@sectionLink.name}}
  @model={{@sectionLink.model}}
  @models={{@sectionLink.models}}
  @prefixType="icon"
  @prefixValue={{@sectionLink.prefixValue}}
  @query={{@sectionLink.query}}
  @route={{@sectionLink.route}}
  @shouldDisplay={{@sectionLink.shouldDisplay}}
  @suffixCSSClass={{@sectionLink.suffixCSSClass}}
  @suffixType={{@sectionLink.suffixType}}
  @suffixValue={{@sectionLink.suffixValue}}
  @title={{@sectionLink.title}}
/>
*/
{
  "id": "cmxGOfba",
  "block": "[[[8,[39,0],null,[[\"@badgeText\",\"@content\",\"@currentWhen\",\"@href\",\"@linkName\",\"@model\",\"@models\",\"@prefixType\",\"@prefixValue\",\"@query\",\"@route\",\"@shouldDisplay\",\"@suffixCSSClass\",\"@suffixType\",\"@suffixValue\",\"@title\"],[[30,1,[\"badgeText\"]],[28,[37,1],[[30,1,[\"text\"]]],null],[30,1,[\"currentWhen\"]],[28,[37,2],[[30,1,[\"href\"]],[30,1,[\"value\"]]],null],[30,1,[\"name\"]],[30,1,[\"model\"]],[30,1,[\"models\"]],\"icon\",[30,1,[\"prefixValue\"]],[30,1,[\"query\"]],[30,1,[\"route\"]],[30,1,[\"shouldDisplay\"]],[30,1,[\"suffixCSSClass\"]],[30,1,[\"suffixType\"]],[30,1,[\"suffixValue\"]],[30,1,[\"title\"]]]],null]],[\"@sectionLink\"],false,[\"sidebar/section-link\",\"replace-emoji\",\"or\"]]",
  "moduleName": "discourse/components/sidebar/more-section-link.hbs",
  "isStrictMode": false
});