import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _class;
import { userPath } from "discourse/lib/url";
import { postUrl } from "discourse/lib/utilities";
import { NEW_PRIVATE_MESSAGE_KEY, NEW_TOPIC_KEY } from "discourse/models/composer";
import RestModel from "discourse/models/rest";
import User from "discourse/models/user";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
let UserDraft = (_dec = discourseComputed("draft_username"), _dec2 = discourseComputed("username_lower"), _dec3 = discourseComputed("topic_id"), _dec4 = discourseComputed("draft_key"), (_class = class UserDraft extends RestModel {
  editableDraft(draftUsername) {
    return draftUsername === User.currentProp("username");
  }
  userUrl(usernameLower) {
    return userPath(usernameLower);
  }
  postUrl(topicId) {
    if (!topicId) {
      return;
    }
    return postUrl(this.slug, this.topic_id, this.post_number);
  }
  draftType(draftKey) {
    switch (draftKey) {
      case NEW_TOPIC_KEY:
        return I18n.t("drafts.new_topic");
      case NEW_PRIVATE_MESSAGE_KEY:
        return I18n.t("drafts.new_private_message");
      default:
        return false;
    }
  }
}, (_applyDecoratedDescriptor(_class.prototype, "editableDraft", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "editableDraft"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userUrl", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "userUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postUrl", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "postUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "draftType", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "draftType"), _class.prototype)), _class));
export { UserDraft as default };