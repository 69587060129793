import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Sidebar::Section
  @sectionName="messages"
  @headerActionIcon="plus"
  @headerActions={{array
    (hash
      action=(fn (route-action "composePrivateMessage") null null)
      title=(i18n "sidebar.sections.messages.header_action_title")
    )
  }}
  @headerActionsIcon="plus"
  @headerLinkText={{i18n "sidebar.sections.messages.header_link_text"}}
  @collapsable={{@collapsable}}
>
  {{#each this.personalMessagesSectionLinks as |personalMessageSectionLink|}}
    {{#if personalMessageSectionLink.shouldDisplay}}
      <Sidebar::SectionLink
        @linkName={{personalMessageSectionLink.name}}
        @linkClass={{personalMessageSectionLink.class}}
        @route={{personalMessageSectionLink.route}}
        @model={{personalMessageSectionLink.model}}
        @prefixType={{personalMessageSectionLink.prefixType}}
        @prefixValue={{personalMessageSectionLink.prefixValue}}
        @currentWhen={{personalMessageSectionLink.currentWhen}}
        @content={{personalMessageSectionLink.text}}
      />
    {{/if}}
  {{/each}}

  {{#if (gt this.groupMessagesSectionLinks.length 0)}}
    {{#each this.groupMessagesSectionLinks as |groupMessageSectionLink|}}
      {{#if groupMessageSectionLink.shouldDisplay}}
        <Sidebar::SectionLink
          @linkName={{groupMessageSectionLink.name}}
          @linkClass={{groupMessageSectionLink.class}}
          @route={{groupMessageSectionLink.route}}
          @prefixType={{groupMessageSectionLink.prefixType}}
          @prefixValue={{groupMessageSectionLink.prefixValue}}
          @models={{groupMessageSectionLink.models}}
          @currentWhen={{groupMessageSectionLink.currentWhen}}
          @content={{groupMessageSectionLink.text}}
        />
      {{/if}}
    {{/each}}
  {{/if}}
</Sidebar::Section>
*/
{
  "id": "Xi2UQra4",
  "block": "[[[8,[39,0],null,[[\"@sectionName\",\"@headerActionIcon\",\"@headerActions\",\"@headerActionsIcon\",\"@headerLinkText\",\"@collapsable\"],[\"messages\",\"plus\",[28,[37,1],[[28,[37,2],null,[[\"action\",\"title\"],[[28,[37,3],[[28,[37,4],[\"composePrivateMessage\"],null],null,null],null],[28,[37,5],[\"sidebar.sections.messages.header_action_title\"],null]]]]],null],\"plus\",[28,[37,5],[\"sidebar.sections.messages.header_link_text\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"personalMessagesSectionLinks\"]]],null]],null],null,[[[41,[30,2,[\"shouldDisplay\"]],[[[1,\"      \"],[8,[39,9],null,[[\"@linkName\",\"@linkClass\",\"@route\",\"@model\",\"@prefixType\",\"@prefixValue\",\"@currentWhen\",\"@content\"],[[30,2,[\"name\"]],[30,2,[\"class\"]],[30,2,[\"route\"]],[30,2,[\"model\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"currentWhen\"]],[30,2,[\"text\"]]]],null],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"\\n\"],[41,[28,[37,10],[[30,0,[\"groupMessagesSectionLinks\",\"length\"]],0],null],[[[42,[28,[37,7],[[28,[37,7],[[30,0,[\"groupMessagesSectionLinks\"]]],null]],null],null,[[[41,[30,3,[\"shouldDisplay\"]],[[[1,\"        \"],[8,[39,9],null,[[\"@linkName\",\"@linkClass\",\"@route\",\"@prefixType\",\"@prefixValue\",\"@models\",\"@currentWhen\",\"@content\"],[[30,3,[\"name\"]],[30,3,[\"class\"]],[30,3,[\"route\"]],[30,3,[\"prefixType\"]],[30,3,[\"prefixValue\"]],[30,3,[\"models\"]],[30,3,[\"currentWhen\"]],[30,3,[\"text\"]]]],null],[1,\"\\n\"]],[]],null]],[3]],null]],[]],null]],[]]]]]],[\"@collapsable\",\"personalMessageSectionLink\",\"groupMessageSectionLink\"],false,[\"sidebar/section\",\"array\",\"hash\",\"fn\",\"route-action\",\"i18n\",\"each\",\"-track-array\",\"if\",\"sidebar/section-link\",\"gt\"]]",
  "moduleName": "discourse/components/sidebar/user/messages-section.hbs",
  "isStrictMode": false
});