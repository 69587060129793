import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { action } from "@ember/object";
import { service } from "@ember/service";
import { resetCachedTopicList } from "discourse/lib/cached-topic-list";
import User from "discourse/models/user";
import DiscourseRoute from "discourse/routes/discourse";

/**
  The parent route for all discovery routes.
**/
let DiscoveryRoute = (_class = class DiscoveryRoute extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _defineProperty(this, "queryParams", {
      filter: {
        refreshModel: true
      }
    });
  }
  redirect() {
    return this.redirectIfLoginRequired();
  }
  beforeModel(transition) {
    const url = transition.intent.url;
    let matches;
    if ((url === "/" || url === "/latest" || url === "/categories") && !transition.targetName.includes("discovery.top") && User.currentProp("user_option.should_be_redirected_to_top")) {
      User.currentProp("user_option.should_be_redirected_to_top", false);
      const period = User.currentProp("user_option.redirected_to_top.period") || "all";
      this.router.replaceWith("discovery.top", {
        queryParams: {
          period
        }
      });
    } else if (url && (matches = url.match(/top\/(.*)$/))) {
      if (this.site.periods.includes(matches[1])) {
        this.router.replaceWith("discovery.top", {
          queryParams: {
            period: matches[1]
          }
        });
      }
    }
  }

  // clear a pinned topic
  clearPin(topic) {
    topic.clearPin();
  }
  refresh() {
    resetCachedTopicList(this.session);
    super.refresh();
  }
  triggerRefresh() {
    this.refresh();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "clearPin", [action], Object.getOwnPropertyDescriptor(_class.prototype, "clearPin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerRefresh"), _class.prototype)), _class);
export { DiscoveryRoute as default };