import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="sidebar-section-link-wrapper">
  <button
    type="button"
    class="btn btn-flat sidebar-section-link-button sidebar-row"
    {{on "click" @action}}
  >
    <span class="sidebar-section-link-prefix icon">
      {{d-icon @icon}}
    </span>

    <span class="sidebar-section-link-content-text">
      {{@text}}
    </span>
  </button>
</div>
*/
{
  "id": "OIP7G0vz",
  "block": "[[[10,0],[14,0,\"sidebar-section-link-wrapper\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-flat sidebar-section-link-button sidebar-row\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"sidebar-section-link-prefix icon\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[[30,2]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"sidebar-section-link-content-text\"],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@action\",\"@icon\",\"@text\"],false,[\"on\",\"d-icon\"]]",
  "moduleName": "discourse/components/sidebar/section-link-button.hbs",
  "isStrictMode": false
});