import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="hamburger-panel">
  <div
    class="revamped menu-panel drop-down"
    data-max-width="320"
    {{did-insert this.triggerRenderedAppEvent}}
  >
    <div class="panel-body">
      <div class="panel-body-contents">
        <div class="sidebar-hamburger-dropdown">
          {{#if this.sidebarState.showMainPanel}}
            <Sidebar::Sections
              @currentUser={{this.currentUser}}
              @collapsableSections={{this.collapsableSections}}
              @panel={{this.sidebarState.currentPanel}}
            />
          {{else}}
            <Sidebar::ApiPanels
              @currentUser={{this.currentUser}}
              @collapsableSections={{this.collapsableSections}}
            />
          {{/if}}
          <Sidebar::Footer @tagName="" />
        </div>
      </div>
    </div>
  </div>
</div>
*/
{
  "id": "yFYd3+ZF",
  "block": "[[[10,0],[14,0,\"hamburger-panel\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"revamped menu-panel drop-down\"],[24,\"data-max-width\",\"320\"],[4,[38,0],[[30,0,[\"triggerRenderedAppEvent\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"panel-body-contents\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"sidebar-hamburger-dropdown\"],[12],[1,\"\\n\"],[41,[30,0,[\"sidebarState\",\"showMainPanel\"]],[[[1,\"            \"],[8,[39,2],null,[[\"@currentUser\",\"@collapsableSections\",\"@panel\"],[[30,0,[\"currentUser\"]],[30,0,[\"collapsableSections\"]],[30,0,[\"sidebarState\",\"currentPanel\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[39,3],null,[[\"@currentUser\",\"@collapsableSections\"],[[30,0,[\"currentUser\"]],[30,0,[\"collapsableSections\"]]]],null],[1,\"\\n\"]],[]]],[1,\"          \"],[8,[39,4],null,[[\"@tagName\"],[\"\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"did-insert\",\"if\",\"sidebar/sections\",\"sidebar/api-panels\",\"sidebar/footer\"]]",
  "moduleName": "discourse/components/sidebar/hamburger-dropdown.hbs",
  "isStrictMode": false
});