import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _CookText;
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import didUpdate from "@ember/render-modifiers/modifiers/did-update";
import { service } from "@ember/service";
import { resolveAllShortUrls } from "pretty-text/upload-short-url";
import { ajax } from "discourse/lib/ajax";
import { loadOneboxes } from "discourse/lib/load-oneboxes";
import { cook } from "discourse/lib/text";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let CookText = (_class = (_CookText = class CookText extends Component {
  constructor(owner1, args1) {
    super(owner1, args1);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    _initializerDefineProperty(this, "cooked", _descriptor2, this);
    this.loadCookedText();
  }
  async loadCookedText() {
    const cooked1 = await cook(this.args.rawText);
    this.cooked = cooked1;
  }
  calculateOffsetHeight(element1) {
    if (!this.args.onOffsetHeightCalculated) {
      return;
    }
    return this.args.onOffsetHeightCalculated(element1?.offsetHeight);
  }
  buildOneboxes(element1) {
    if (this.args.paintOneboxes && this.cooked !== null) {
      loadOneboxes(element1, ajax, this.args.topicId, this.args.categoryId, this.siteSettings.max_oneboxes_per_post, false // refresh
      );
    }
  }
  resolveShortUrls(element1) {
    resolveAllShortUrls(ajax, this.siteSettings, element1, this.args.opts);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div
      ...attributes
      {{didUpdate this.buildOneboxes this.cooked}}
      {{didUpdate this.resolveShortUrls this.cooked}}
      {{didUpdate this.calculateOffsetHeight this.cooked}}
      {{didUpdate this.loadCookedText @rawText}}
    >
      {{this.cooked}}
    </div>
  
*/
{
  "id": "Gnx6a88S",
  "block": "[[[1,\"\\n    \"],[11,0],[17,1],[4,[32,0],[[30,0,[\"buildOneboxes\"]],[30,0,[\"cooked\"]]],null],[4,[32,0],[[30,0,[\"resolveShortUrls\"]],[30,0,[\"cooked\"]]],null],[4,[32,0],[[30,0,[\"calculateOffsetHeight\"]],[30,0,[\"cooked\"]]],null],[4,[32,0],[[30,0,[\"loadCookedText\"]],[30,2]],null],[12],[1,\"\\n      \"],[1,[30,0,[\"cooked\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@rawText\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/cook-text.js",
  "scope": () => [didUpdate],
  "isStrictMode": true
}), _CookText), _CookText), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cooked", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class.prototype, "loadCookedText", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loadCookedText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calculateOffsetHeight", [action], Object.getOwnPropertyDescriptor(_class.prototype, "calculateOffsetHeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildOneboxes", [action], Object.getOwnPropertyDescriptor(_class.prototype, "buildOneboxes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resolveShortUrls", [action], Object.getOwnPropertyDescriptor(_class.prototype, "resolveShortUrls"), _class.prototype)), _class);
export { CookText as default };