import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.activeSectionLink}}
  <Sidebar::MoreSectionLink @sectionLink={{this.activeSectionLink}} />
{{/if}}

<li class="sidebar-section-link-wrapper">
  <DButton
    @icon="ellipsis-v"
    @action={{this.toggleSectionLinks}}
    @label="sidebar.more"
    class="btn-flat sidebar-more-section-links-details-summary"
    aria-expanded={{if this.open "true" "false"}}
  />
</li>

{{#if this.open}}
  <div class="sidebar-more-section-links-details">
    <div
      class="sidebar-more-section-links-details-content-wrapper"
      {{did-insert this.registerClickListener}}
      {{will-destroy this.unregisterClickListener}}
    >

      <div class="sidebar-more-section-links-details-content">
        <ul class="sidebar-more-section-links-details-content-main">
          {{#each this.sectionLinks as |sectionLink|}}
            <Sidebar::MoreSectionLink @sectionLink={{sectionLink}} />
          {{/each}}
        </ul>

        {{#if @moreButtonAction}}
          <div class="sidebar-more-section-links-details-content-footer">
            <Sidebar::SectionLinkButton
              @action={{@moreButtonAction}}
              @icon={{@moreButtonIcon}}
              @text={{@moreButtonText}}
            />
          </div>
        {{/if}}
      </div>
    </div>
  </div>
{{/if}}
*/
{
  "id": "Yw3rpoFI",
  "block": "[[[41,[30,0,[\"activeSectionLink\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@sectionLink\"],[[30,0,[\"activeSectionLink\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,\"li\"],[14,0,\"sidebar-section-link-wrapper\"],[12],[1,\"\\n  \"],[8,[39,2],[[24,0,\"btn-flat sidebar-more-section-links-details-summary\"],[16,\"aria-expanded\",[52,[30,0,[\"open\"]],\"true\",\"false\"]]],[[\"@icon\",\"@action\",\"@label\"],[\"ellipsis-v\",[30,0,[\"toggleSectionLinks\"]],\"sidebar.more\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"open\"]],[[[1,\"  \"],[10,0],[14,0,\"sidebar-more-section-links-details\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"sidebar-more-section-links-details-content-wrapper\"],[4,[38,3],[[30,0,[\"registerClickListener\"]]],null],[4,[38,4],[[30,0,[\"unregisterClickListener\"]]],null],[12],[1,\"\\n\\n      \"],[10,0],[14,0,\"sidebar-more-section-links-details-content\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"sidebar-more-section-links-details-content-main\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"            \"],[8,[39,1],null,[[\"@sectionLink\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"          \"],[10,0],[14,0,\"sidebar-more-section-links-details-content-footer\"],[12],[1,\"\\n            \"],[8,[39,7],null,[[\"@action\",\"@icon\",\"@text\"],[[30,2],[30,3],[30,4]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"sectionLink\",\"@moreButtonAction\",\"@moreButtonIcon\",\"@moreButtonText\"],false,[\"if\",\"sidebar/more-section-link\",\"d-button\",\"did-insert\",\"will-destroy\",\"each\",\"-track-array\",\"sidebar/section-link-button\"]]",
  "moduleName": "discourse/components/sidebar/more-section-links.hbs",
  "isStrictMode": false
});