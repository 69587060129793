import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
import templateOnly from "@ember/component/template-only";
const PoweredByDiscourse = setComponentTemplate(createTemplateFactory(
/*
  
  <a class="powered-by-discourse" href="https://discourse.org">
    <span class="powered-by-discourse__content">
      <span class="powered-by-discourse__logo">
        {{icon "fab-discourse"}}
      </span>
      <span>{{i18n "powered_by_discourse"}}</span>
    </span>
  </a>

*/
{
  "id": "wY+iFr+q",
  "block": "[[[1,\"\\n  \"],[10,3],[14,0,\"powered-by-discourse\"],[14,6,\"https://discourse.org\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"powered-by-discourse__content\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"powered-by-discourse__logo\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"fab-discourse\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[12],[1,[28,[32,1],[\"powered_by_discourse\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/powered-by-discourse.js",
  "scope": () => [icon, i18n],
  "isStrictMode": true
}), templateOnly());
export default PoweredByDiscourse;