import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
/* import __COLOCATED_TEMPLATE__ from './hamburger-dropdown.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import TEMPLATE from "./hamburger-dropdown.hbs";
import { setComponentTemplate } from "@ember/component";
let SidebarHamburgerDropdown = (_class = class SidebarHamburgerDropdown extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "appEvents", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "site", _descriptor3, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor4, this);
    _initializerDefineProperty(this, "sidebarState", _descriptor5, this);
  }
  triggerRenderedAppEvent() {
    this.appEvents.trigger("sidebar-hamburger-dropdown:rendered");
  }
  get collapsableSections() {
    if (this.siteSettings.navigation_menu === "header dropdown" && !this.args.collapsableSections) {
      return this.site.mobileView || this.site.narrowDesktopView;
    } else {
      this.args.collapsableSections;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "triggerRenderedAppEvent", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerRenderedAppEvent"), _class.prototype)), _class);
export { SidebarHamburgerDropdown as default };
setComponentTemplate(TEMPLATE, SidebarHamburgerDropdown);