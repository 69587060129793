import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './switch-panel-buttons.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { defaultHomepage } from "discourse/lib/utilities";
import getURL from "discourse-common/lib/get-url";
import TEMPLATE from "./switch-panel-buttons.hbs";
import { setComponentTemplate } from "@ember/component";
let SwitchPanelButtons = (_class = class SwitchPanelButtons extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "sidebarState", _descriptor2, this);
    _initializerDefineProperty(this, "currentPanel", _descriptor3, this);
    _initializerDefineProperty(this, "isSwitching", _descriptor4, this);
  }
  get destination() {
    if (this.currentPanel) {
      const url = this.currentPanel.switchButtonDefaultUrl || this.currentPanel.lastKnownURL;
      return url === "/" ? `discovery.${defaultHomepage()}` : getURL(url);
    }
    return null;
  }
  async switchPanel(panel) {
    this.isSwitching = true;
    this.currentPanel = panel;
    this.sidebarState.currentPanel.lastKnownURL = this.router.currentURL;
    if (this.destination) {
      try {
        await this.router.transitionTo(this.destination).followRedirects();
        this.sidebarState.setPanel(this.currentPanel.key);
      } catch (e) {
        if (e.name !== "TransitionAborted") {
          throw e;
        }
      } finally {
        this.isSwitching = false;
      }
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentPanel", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isSwitching", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "switchPanel", [action], Object.getOwnPropertyDescriptor(_class.prototype, "switchPanel"), _class.prototype)), _class);
export { SwitchPanelButtons as default };
setComponentTemplate(TEMPLATE, SwitchPanelButtons);