import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
/* import __COLOCATED_TEMPLATE__ from './tags-section.hbs'; */
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { action } from "@ember/object";
import { service } from "@ember/service";
import SidebarEditNavigationMenuTagsModal from "discourse/components/sidebar/edit-navigation-menu/tags-modal";
import { hasDefaultSidebarTags } from "discourse/lib/sidebar/helpers";
import PMTagSectionLink from "discourse/lib/sidebar/user/tags-section/pm-tag-section-link";
import TagSectionLink from "discourse/lib/sidebar/user/tags-section/tag-section-link";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
import TEMPLATE from "./tags-section.hbs";
import { setComponentTemplate } from "@ember/component";
let SidebarUserTagsSection = (_class = class SidebarUserTagsSection extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "modal", _descriptor2, this);
    _initializerDefineProperty(this, "pmTopicTrackingState", _descriptor3, this);
    _initializerDefineProperty(this, "router", _descriptor4, this);
    _initializerDefineProperty(this, "site", _descriptor5, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor6, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor7, this);
    this.callbackId = this.topicTrackingState.onStateChange(() => {
      this.sectionLinks.forEach(sectionLink => {
        if (sectionLink.refreshCounts) {
          sectionLink.refreshCounts();
        }
      });
    });
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.topicTrackingState.offStateChange(this.callbackId);
  }
  get sectionLinks() {
    const links = [];
    let tags;
    if (this.currentUser.sidebarTags.length > 0) {
      tags = this.currentUser.sidebarTags;
    } else {
      tags = this.site.navigation_menu_site_top_tags || [];
    }
    for (const tag of tags) {
      if (tag.pm_only) {
        links.push(new PMTagSectionLink({
          tag,
          currentUser: this.currentUser
        }));
      } else {
        links.push(new TagSectionLink({
          tag,
          topicTrackingState: this.topicTrackingState,
          currentUser: this.currentUser
        }));
      }
    }
    return links;
  }
  get shouldDisplayDefaultConfig() {
    return this.currentUser.admin && !this.hasDefaultSidebarTags;
  }
  get hasDefaultSidebarTags() {
    return hasDefaultSidebarTags(this.siteSettings);
  }
  showModal() {
    this.modal.show(SidebarEditNavigationMenuTagsModal);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pmTopicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "sectionLinks", [cached], Object.getOwnPropertyDescriptor(_class.prototype, "sectionLinks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showModal", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showModal"), _class.prototype)), _class);
export { SidebarUserTagsSection as default };
setComponentTemplate(TEMPLATE, SidebarUserTagsSection);