var _ComposerSaveButton;
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
import concatClass from "discourse/helpers/concat-class";
import { translateModKey } from "discourse/lib/utilities";
import I18n from "discourse-i18n";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
export default class ComposerSaveButton extends Component {
  get translatedTitle() {
    return I18n.t("composer.title", {
      modifier: translateModKey("Meta+")
    });
  }
}
_ComposerSaveButton = ComposerSaveButton;
setComponentTemplate(createTemplateFactory(
/*
  
    <DButton
      @action={{@action}}
      @label={{@label}}
      @icon={{@icon}}
      @translatedTitle={{this.translatedTitle}}
      @forwardEvent={{@forwardEvent}}
      class={{concatClass "btn-primary create" (if @disabledSubmit "disabled")}}
      ...attributes
    />
  
*/
{
  "id": "14dcz4HJ",
  "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"btn-primary create\",[52,[30,1],\"disabled\"]],null]],[17,2]],[[\"@action\",\"@label\",\"@icon\",\"@translatedTitle\",\"@forwardEvent\"],[[30,3],[30,4],[30,5],[30,0,[\"translatedTitle\"]],[30,6]]],null],[1,\"\\n  \"]],[\"@disabledSubmit\",\"&attrs\",\"@action\",\"@label\",\"@icon\",\"@forwardEvent\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/composer-save-button.js",
  "scope": () => [DButton, concatClass],
  "isStrictMode": true
}), _ComposerSaveButton);