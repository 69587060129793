import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Sidebar::Section
  @sectionName="tags"
  @headerLinkText={{i18n "sidebar.sections.tags.header_link_text"}}
  @headerActions={{array
    (hash
      action=this.showModal
      title=(i18n "sidebar.sections.tags.header_action_title")
    )
  }}
  @headerActionsIcon="pencil-alt"
  @collapsable={{@collapsable}}
>

  {{#if (gt this.sectionLinks.length 0)}}
    {{#each this.sectionLinks as |sectionLink|}}
      <Sidebar::SectionLink
        @route={{sectionLink.route}}
        @title={{sectionLink.title}}
        @content={{sectionLink.text}}
        @currentWhen={{sectionLink.currentWhen}}
        @prefixType={{sectionLink.prefixType}}
        @prefixValue={{sectionLink.prefixValue}}
        @prefixColor={{sectionLink.prefixColor}}
        @badgeText={{sectionLink.badgeText}}
        @models={{sectionLink.models}}
        @suffixCSSClass={{sectionLink.suffixCSSClass}}
        @suffixValue={{sectionLink.suffixValue}}
        @suffixType={{sectionLink.suffixType}}
        data-tag-name={{sectionLink.tagName}}
      />
    {{/each}}
  {{/if}}

  <Sidebar::Common::AllTagsSectionLink />

  {{#if this.shouldDisplayDefaultConfig}}
    <Sidebar::SectionLink
      @linkName="configure-default-navigation-menu-tags"
      @content={{i18n "sidebar.sections.tags.configure_defaults"}}
      @prefixType="icon"
      @prefixValue="wrench"
      @route="adminSiteSettingsCategory"
      @model="sidebar"
      @query={{hash filter="default_navigation_menu_tags"}}
    />
  {{/if}}
</Sidebar::Section>
*/
{
  "id": "qG53i+PS",
  "block": "[[[8,[39,0],null,[[\"@sectionName\",\"@headerLinkText\",\"@headerActions\",\"@headerActionsIcon\",\"@collapsable\"],[\"tags\",[28,[37,1],[\"sidebar.sections.tags.header_link_text\"],null],[28,[37,2],[[28,[37,3],null,[[\"action\",\"title\"],[[30,0,[\"showModal\"]],[28,[37,1],[\"sidebar.sections.tags.header_action_title\"],null]]]]],null],\"pencil-alt\",[30,1]]],[[\"default\"],[[[[1,\"\\n\\n\"],[41,[28,[37,5],[[30,0,[\"sectionLinks\",\"length\"]],0],null],[[[42,[28,[37,7],[[28,[37,7],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"      \"],[8,[39,8],[[16,\"data-tag-name\",[30,2,[\"tagName\"]]]],[[\"@route\",\"@title\",\"@content\",\"@currentWhen\",\"@prefixType\",\"@prefixValue\",\"@prefixColor\",\"@badgeText\",\"@models\",\"@suffixCSSClass\",\"@suffixValue\",\"@suffixType\"],[[30,2,[\"route\"]],[30,2,[\"title\"]],[30,2,[\"text\"]],[30,2,[\"currentWhen\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"prefixColor\"]],[30,2,[\"badgeText\"]],[30,2,[\"models\"]],[30,2,[\"suffixCSSClass\"]],[30,2,[\"suffixValue\"]],[30,2,[\"suffixType\"]]]],null],[1,\"\\n\"]],[2]],null]],[]],null],[1,\"\\n  \"],[8,[39,9],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"shouldDisplayDefaultConfig\"]],[[[1,\"    \"],[8,[39,8],null,[[\"@linkName\",\"@content\",\"@prefixType\",\"@prefixValue\",\"@route\",\"@model\",\"@query\"],[\"configure-default-navigation-menu-tags\",[28,[37,1],[\"sidebar.sections.tags.configure_defaults\"],null],\"icon\",\"wrench\",\"adminSiteSettingsCategory\",\"sidebar\",[28,[37,3],null,[[\"filter\"],[\"default_navigation_menu_tags\"]]]]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@collapsable\",\"sectionLink\"],false,[\"sidebar/section\",\"i18n\",\"array\",\"hash\",\"if\",\"gt\",\"each\",\"-track-array\",\"sidebar/section-link\",\"sidebar/common/all-tags-section-link\"]]",
  "moduleName": "discourse/components/sidebar/user/tags-section.hbs",
  "isStrictMode": false
});