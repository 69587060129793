import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
import SidebarCustomSection from "discourse/components/sidebar/common/custom-sections";
import { ajax } from "discourse/lib/ajax";
import { bind } from "discourse-common/utils/decorators";
let SidebarUserCustomSections = (_class = class SidebarUserCustomSections extends SidebarCustomSection {
  constructor() {
    super(...arguments);
    this.messageBus.subscribe("/refresh-sidebar-sections", this._refresh);
  }
  willDestroy() {
    this.messageBus.unsubscribe("/refresh-sidebar-sections");
  }
  _refresh() {
    return ajax("/sidebar_sections.json", {}).then(json => {
      this.currentUser.set("sidebar_sections", json.sidebar_sections);
    });
  }
}, (_applyDecoratedDescriptor(_class.prototype, "_refresh", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "_refresh"), _class.prototype)), _class);
export { SidebarUserCustomSections as default };