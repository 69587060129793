import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="sidebar-sections">
  <Sidebar::ApiSections @collapsable={{@collapsableSections}} />
</div>
*/
{
  "id": "H8UYFLDV",
  "block": "[[[10,0],[14,0,\"sidebar-sections\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"],[13]],[\"@collapsableSections\"],false,[\"sidebar/api-sections\"]]",
  "moduleName": "discourse/components/sidebar/api-panels.hbs",
  "isStrictMode": false
});