import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
import { action } from "@ember/object";
import { service } from "@ember/service";
import { hash } from "rsvp";
import { ajax } from "discourse/lib/ajax";
import PreloadStore from "discourse/lib/preload-store";
import { defaultHomepage } from "discourse/lib/utilities";
import CategoryList from "discourse/models/category-list";
import TopicList from "discourse/models/topic-list";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let DiscoveryCategoriesRoute = (_class = class DiscoveryCategoriesRoute extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "modal", _descriptor, this);
    _initializerDefineProperty(this, "router", _descriptor2, this);
    _initializerDefineProperty(this, "session", _descriptor3, this);
    _defineProperty(this, "templateName", "discovery/categories");
    _defineProperty(this, "controllerName", "discovery/categories");
  }
  findCategories() {
    let style = this.site.desktopView && this.siteSettings.desktop_category_page_style;
    if (style === "categories_and_latest_topics" || style === "categories_and_latest_topics_created_date") {
      return this._findCategoriesAndTopics("latest");
    } else if (style === "categories_and_top_topics") {
      return this._findCategoriesAndTopics("top");
    } else {
      // The server may have serialized this. Based on the logic above, we don't need it
      // so remove it to avoid it being used later by another TopicList route.
      PreloadStore.remove("topic_list");
    }
    return CategoryList.list(this.store);
  }
  model() {
    return this.findCategories().then(model => {
      const tracking = this.topicTrackingState;
      if (tracking) {
        tracking.sync(model, "categories");
        tracking.trackIncoming("categories");
      }
      return model;
    });
  }
  _loadBefore(store) {
    const session = this.session;
    return function (topic_ids, storeInSession) {
      // refresh dupes
      this.topics.removeObjects(this.topics.filter(topic => topic_ids.includes(topic.id)));
      const url = `/latest.json?topic_ids=${topic_ids.join(",")}`;
      return ajax({
        url,
        data: this.params
      }).then(result => {
        const topicIds = new Set();
        this.topics.forEach(topic => topicIds.add(topic.id));
        let i = 0;
        TopicList.topicsFrom(store, result).forEach(topic => {
          if (!topicIds.has(topic.id)) {
            topic.set("highlight", true);
            this.topics.insertAt(i, topic);
            i++;
          }
        });
        if (storeInSession) {
          session.set("topicList", this);
        }
      });
    };
  }
  async _findCategoriesAndTopics(filter) {
    return hash({
      categoriesList: PreloadStore.getAndRemove("categories_list"),
      topicsList: PreloadStore.getAndRemove("topic_list")
    }).then(result => {
      if (result.categoriesList?.category_list && result.topicsList?.topic_list) {
        return {
          ...result.categoriesList,
          ...result.topicsList
        };
      } else {
        // Otherwise, return the ajax result
        return ajax(`/categories_and_${filter}`);
      }
    }).then(result => {
      if (result.topic_list?.top_tags) {
        this.site.set("top_tags", result.topic_list.top_tags);
      }
      return CategoryList.create({
        store: this.store,
        categories: CategoryList.categoriesFrom(this.store, result),
        topics: TopicList.topicsFrom(this.store, result),
        can_create_category: result.category_list.can_create_category,
        can_create_topic: result.category_list.can_create_topic,
        loadBefore: this._loadBefore(this.store)
      });
    });
  }
  titleToken() {
    if (defaultHomepage() === "categories") {
      return;
    }
    return I18n.t("filters.categories.title");
  }
  setupController(controller) {
    controller.setProperties({
      discovery: this.controllerFor("discovery")
    });
    super.setupController(...arguments);
  }
  triggerRefresh() {
    this.refresh();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_class.prototype, "triggerRefresh"), _class.prototype)), _class);
export { DiscoveryCategoriesRoute as default };