import LightDarkImg from "discourse/components/light-dark-img";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
import templateOnly from "@ember/component/template-only";
const CategoryLogo = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="category-logo aspect-image">
    <LightDarkImg
      @lightImg={{@category.uploaded_logo}}
      @darkImg={{@category.uploaded_logo_dark}}
    />
  </div>

*/
{
  "id": "z+CHxD7Q",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"category-logo aspect-image\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@lightImg\",\"@darkImg\"],[[30,1,[\"uploaded_logo\"]],[30,1,[\"uploaded_logo_dark\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@category\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/category-logo.js",
  "scope": () => [LightDarkImg],
  "isStrictMode": true
}), templateOnly());
export default CategoryLogo;