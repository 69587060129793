import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="sidebar-sections">
  <Sidebar::User::CustomSections @collapsable={{@collapsableSections}} />
  <Sidebar::User::CategoriesSection @collapsable={{@collapsableSections}} />

  {{#if this.currentUser.display_sidebar_tags}}
    <Sidebar::User::TagsSection @collapsable={{@collapsableSections}} />
  {{/if}}

  {{#if this.enableMessagesSection}}
    <Sidebar::User::MessagesSection @collapsable={{@collapsableSections}} />
  {{/if}}

  <Sidebar::ApiSections @collapsable={{@collapsableSections}} />
</div>
*/
{
  "id": "Cp86GXea",
  "block": "[[[10,0],[14,0,\"sidebar-sections\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"display_sidebar_tags\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"enableMessagesSection\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"],[13]],[\"@collapsableSections\"],false,[\"sidebar/user/custom-sections\",\"sidebar/user/categories-section\",\"if\",\"sidebar/user/tags-section\",\"sidebar/user/messages-section\",\"sidebar/api-sections\"]]",
  "moduleName": "discourse/components/sidebar/user/sections.hbs",
  "isStrictMode": false
});