import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _PageLoadingSlider;
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { cancel, next } from "@ember/runloop";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { eq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
import { bind } from "discourse-common/utils/decorators";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let PageLoadingSlider = (_class = (_PageLoadingSlider = class PageLoadingSlider extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "loadingSlider", _descriptor, this);
    _initializerDefineProperty(this, "capabilities", _descriptor2, this);
    _initializerDefineProperty(this, "state", _descriptor3, this);
    this.loadingSlider.on("stateChanged", this.stateChanged);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.loadingSlider.off("stateChange", this, "stateChange");
  }
  stateChanged(loading1) {
    if (this._deferredStateChange) {
      cancel(this._deferredStateChange);
      this._deferredStateChange = null;
    }
    if (loading1 && this.ready) {
      this.state = "loading";
    } else if (loading1) {
      this.state = "ready";
      this._deferredStateChange = next(() => this.state = "loading");
    } else {
      this.state = "done";
    }
  }
  get containerStyle() {
    const duration1 = this.loadingSlider.averageLoadingDuration.toFixed(2);
    return htmlSafe(`--loading-duration: ${duration1}s`);
  }
  onContainerTransitionEnd(event1) {
    if (event1.target === event1.currentTarget && event1.propertyName === "opacity") {
      this.state = "ready";
    }
  }
  onBarTransitionEnd(event1) {
    if (event1.target === event1.currentTarget && event1.propertyName === "transform" && this.state === "loading") {
      this.state = "still-loading";
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if (eq this.loadingSlider.mode "slider")}}
      <div
        {{on "transitionend" this.onContainerTransitionEnd}}
        style={{this.containerStyle}}
        class={{concatClass
          "loading-indicator-container"
          this.state
          (if this.capabilities.isAppWebview "discourse-hub-webview")
        }}
      >
        <div
          {{on "transitionend" this.onBarTransitionEnd}}
          class="loading-indicator"
        >
        </div>
      </div>
    {{/if}}
  
*/
{
  "id": "FycjnmDY",
  "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"loadingSlider\",\"mode\"]],\"slider\"],null],[[[1,\"      \"],[11,0],[16,5,[30,0,[\"containerStyle\"]]],[16,0,[28,[32,1],[\"loading-indicator-container\",[30,0,[\"state\"]],[52,[30,0,[\"capabilities\",\"isAppWebview\"]],\"discourse-hub-webview\"]],null]],[4,[32,2],[\"transitionend\",[30,0,[\"onContainerTransitionEnd\"]]],null],[12],[1,\"\\n        \"],[11,0],[24,0,\"loading-indicator\"],[4,[32,2],[\"transitionend\",[30,0,[\"onBarTransitionEnd\"]]],null],[12],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/page-loading-slider.js",
  "scope": () => [eq, concatClass, on],
  "isStrictMode": true
}), _PageLoadingSlider), _PageLoadingSlider), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loadingSlider", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "capabilities", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "state", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "ready";
  }
}), _applyDecoratedDescriptor(_class.prototype, "stateChanged", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "stateChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onContainerTransitionEnd", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onContainerTransitionEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBarTransitionEnd", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onBarTransitionEnd"), _class.prototype)), _class);
export { PageLoadingSlider as default };