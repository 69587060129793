import EmberObject from "@ember/object";
import rawRenderGlimmer from "discourse/lib/raw-render-glimmer";
import i18n from "discourse-common/helpers/i18n";
import BulkSelectTopicsDropdown from "select-kit/components/bulk-select-topics-dropdown";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
import templateOnly from "@ember/component/template-only";
export default class extends EmberObject {
  get selectedCount() {
    return this.bulkSelectHelper.selected.length;
  }
  get html() {
    return rawRenderGlimmer(this, "div.bulk-select-topics-dropdown", setComponentTemplate(createTemplateFactory(
    /*
      
            <span class="bulk-select-topic-dropdown__count">
              {{i18n "topics.bulk.selected_count" count=@data.selectedCount}}
            </span>
            <BulkSelectTopicsDropdown
              @bulkSelectHelper={{@data.bulkSelectHelper}}
            />
          
    */
    {
      "id": "PMdsOWPA",
      "block": "[[[1,\"\\n        \"],[10,1],[14,0,\"bulk-select-topic-dropdown__count\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"topics.bulk.selected_count\"],[[\"count\"],[[30,1,[\"selectedCount\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,1],null,[[\"@bulkSelectHelper\"],[[30,1,[\"bulkSelectHelper\"]]]],null],[1,\"\\n      \"]],[\"@data\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/raw-views/topic-bulk-select-dropdown.js",
      "scope": () => [i18n, BulkSelectTopicsDropdown],
      "isStrictMode": true
    }), templateOnly()), {
      bulkSelectHelper: this.bulkSelectHelper,
      selectedCount: this.selectedCount
    });
  }
}