import concatClass from "discourse/helpers/concat-class";
import icon from "discourse-common/helpers/d-icon";
import { avatarUrl, translateSize } from "discourse-common/lib/avatar-utils";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
import templateOnly from "@ember/component/template-only";
const avatarPx = translateSize("medium");
const IconAvatar = setComponentTemplate(createTemplateFactory(
/*
  
  <div class={{concatClass "icon-avatar" @data.classNames}}>
    {{!--
        avoiding {{avatar}} helper because its html would be fully
        re-rendered whenever arguments change, even if the argument values
        are identical. On some browsers, re-rendering a lazy-loaded image
        causes a visible flicker.
      --}}
    <img
      lazy="lazy"
      src={{avatarUrl @data.avatarTemplate "medium"}}
      width={{avatarPx}}
      height={{avatarPx}}
      class="avatar"
    />
    <div class="icon-avatar__icon-wrapper">
      {{icon @data.icon}}
    </div>
  </div>

*/
{
  "id": "XPfRti71",
  "block": "[[[1,\"\\n  \"],[10,0],[15,0,[28,[32,0],[\"icon-avatar\",[30,1,[\"classNames\"]]],null]],[12],[1,\"\\n\"],[1,\"    \"],[10,\"img\"],[14,\"lazy\",\"lazy\"],[15,\"src\",[28,[32,1],[[30,1,[\"avatarTemplate\"]],\"medium\"],null]],[15,\"width\",[32,2]],[15,\"height\",[32,2]],[14,0,\"avatar\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"icon-avatar__icon-wrapper\"],[12],[1,\"\\n      \"],[1,[28,[32,3],[[30,1,[\"icon\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@data\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-menu/icon-avatar.js",
  "scope": () => [concatClass, avatarUrl, avatarPx, icon],
  "isStrictMode": true
}), templateOnly());
export default IconAvatar;