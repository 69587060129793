import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _DismissRead;
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { fn } from "@ember/helper";
import DButton from "discourse/components/d-button";
import DModal from "discourse/components/d-modal";
import PreferenceCheckbox from "discourse/components/preference-checkbox";
import i18n from "discourse-common/helpers/i18n";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let DismissRead = (_class = (_DismissRead = class DismissRead extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "dismissTopics", _descriptor, this);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <DModal
      @closeModal={{@closeModal}}
      @title={{i18n @model.title count=@model.count}}
      class="dismiss-read-modal"
    >
      <:body>
        <p>
          <PreferenceCheckbox
            @labelKey="topics.bulk.also_dismiss_topics"
            @checked={{this.dismissTopics}}
            class="dismiss-read-modal__stop-tracking"
          />
        </p>
      </:body>
      <:footer>
        <DButton
          @action={{fn @model.dismissRead this.dismissTopics}}
          @label="topics.bulk.dismiss"
          @icon="check"
          id="dismiss-read-confirm"
          class="btn-primary"
        />
      </:footer>
    </DModal>
  
*/
{
  "id": "07PSiBZF",
  "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"dismiss-read-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[32,1],[[30,2,[\"title\"]]],[[\"count\"],[[30,2,[\"count\"]]]]]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[8,[32,2],[[24,0,\"dismiss-read-modal__stop-tracking\"]],[[\"@labelKey\",\"@checked\"],[\"topics.bulk.also_dismiss_topics\",[30,0,[\"dismissTopics\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,1,\"dismiss-read-confirm\"],[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@icon\"],[[28,[32,4],[[30,2,[\"dismissRead\"]],[30,0,[\"dismissTopics\"]]],null],\"topics.bulk.dismiss\",\"check\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/modal/dismiss-read.js",
  "scope": () => [DModal, i18n, PreferenceCheckbox, DButton, fn],
  "isStrictMode": true
}), _DismissRead), _DismissRead), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dismissTopics", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
})), _class);
export { DismissRead as default };