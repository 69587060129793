import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if @prefixType}}
  <span
    class={{concat-class
      "sidebar-section-link-prefix"
      @prefixType
      @prefixCSSClass
    }}
    style={{if @prefixColor (html-safe (concat "color: " @prefixColor))}}
  >

    {{#if (eq @prefixType "image")}}
      <img src={{this.prefixValue}} class="prefix-image" />
    {{else if (eq @prefixType "text")}}
      <span class="prefix-text">
        {{this.prefixValue}}
      </span>
    {{else if (eq @prefixType "icon")}}
      {{d-icon this.prefixValue class="prefix-icon"}}
    {{else if (eq @prefixType "span")}}
      <span
        style={{html-safe
          (concat "background: linear-gradient(90deg, " this.prefixValue ")")
        }}
        class="prefix-span"
      ></span>
    {{/if}}

    {{#if @prefixBadge}}
      {{d-icon @prefixBadge class="prefix-badge"}}
    {{/if}}
  </span>
{{/if}}
*/
{
  "id": "OahHlLqS",
  "block": "[[[41,[30,1],[[[1,\"  \"],[10,1],[15,0,[28,[37,1],[\"sidebar-section-link-prefix\",[30,1],[30,2]],null]],[15,5,[52,[30,3],[28,[37,2],[[28,[37,3],[\"color: \",[30,3]],null]],null]]],[12],[1,\"\\n\\n\"],[41,[28,[37,4],[[30,1],\"image\"],null],[[[1,\"      \"],[10,\"img\"],[15,\"src\",[30,0,[\"prefixValue\"]]],[14,0,\"prefix-image\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[[30,1],\"text\"],null],[[[1,\"      \"],[10,1],[14,0,\"prefix-text\"],[12],[1,\"\\n        \"],[1,[30,0,[\"prefixValue\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[[30,1],\"icon\"],null],[[[1,\"      \"],[1,[28,[35,5],[[30,0,[\"prefixValue\"]]],[[\"class\"],[\"prefix-icon\"]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[[30,1],\"span\"],null],[[[1,\"      \"],[10,1],[15,5,[28,[37,2],[[28,[37,3],[\"background: linear-gradient(90deg, \",[30,0,[\"prefixValue\"]],\")\"],null]],null]],[14,0,\"prefix-span\"],[12],[13],[1,\"\\n    \"]],[]],null]],[]]]],[]]]],[]]],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[1,[28,[35,5],[[30,4]],[[\"class\"],[\"prefix-badge\"]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@prefixType\",\"@prefixCSSClass\",\"@prefixColor\",\"@prefixBadge\"],false,[\"if\",\"concat-class\",\"html-safe\",\"concat\",\"eq\",\"d-icon\"]]",
  "moduleName": "discourse/components/sidebar/section-link-prefix.hbs",
  "isStrictMode": false
});