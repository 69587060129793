import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.displaySection}}
  <Sidebar::Section
    @sectionName="tags"
    @headerLinkText={{i18n "sidebar.sections.tags.header_link_text"}}
    @collapsable={{@collapsable}}
  >

    {{#each this.sectionLinks as |sectionLink|}}
      <Sidebar::SectionLink
        @route={{sectionLink.route}}
        @content={{sectionLink.text}}
        @title={{sectionLink.title}}
        @currentWhen={{sectionLink.currentWhen}}
        @prefixType={{sectionLink.prefixType}}
        @prefixValue={{sectionLink.prefixValue}}
        @prefixColor={{sectionLink.prefixColor}}
        @models={{sectionLink.models}}
        data-tag-name={{sectionLink.tagName}}
      />
    {{/each}}

    <Sidebar::Common::AllTagsSectionLink />
  </Sidebar::Section>
{{/if}}
*/
{
  "id": "itGhalZi",
  "block": "[[[41,[30,0,[\"displaySection\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@sectionName\",\"@headerLinkText\",\"@collapsable\"],[\"tags\",[28,[37,2],[\"sidebar.sections.tags.header_link_text\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"      \"],[8,[39,5],[[16,\"data-tag-name\",[30,2,[\"tagName\"]]]],[[\"@route\",\"@content\",\"@title\",\"@currentWhen\",\"@prefixType\",\"@prefixValue\",\"@prefixColor\",\"@models\"],[[30,2,[\"route\"]],[30,2,[\"text\"]],[30,2,[\"title\"]],[30,2,[\"currentWhen\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"prefixColor\"]],[30,2,[\"models\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n    \"],[8,[39,6],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@collapsable\",\"sectionLink\"],false,[\"if\",\"sidebar/section\",\"i18n\",\"each\",\"-track-array\",\"sidebar/section-link\",\"sidebar/common/all-tags-section-link\"]]",
  "moduleName": "discourse/components/sidebar/anonymous/tags-section.hbs",
  "isStrictMode": false
});