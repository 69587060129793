import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Sidebar::AdminHeader />
{{#each this.sections as |sectionConfig|}}
  <Sidebar::ApiSection
    @sectionConfig={{sectionConfig}}
    @collapsable={{@collapsable}}
  />
{{/each}}
<Sidebar::FilterNoResults />
*/
{
  "id": "IbZ/Pyig",
  "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"sections\"]]],null]],null],null,[[[1,\"  \"],[8,[39,3],null,[[\"@sectionConfig\",\"@collapsable\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[1]],null],[8,[39,4],null,null,null]],[\"sectionConfig\",\"@collapsable\"],false,[\"sidebar/admin-header\",\"each\",\"-track-array\",\"sidebar/api-section\",\"sidebar/filter-no-results\"]]",
  "moduleName": "discourse/components/sidebar/api-sections.hbs",
  "isStrictMode": false
});