import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
import Mixin from "@ember/object/mixin";
import Eyeline from "discourse/lib/eyeline";
import Scrolling from "discourse/mixins/scrolling";
import { on } from "discourse-common/utils/decorators";

// Provides the ability to load more items for a view which is scrolled to the bottom.
export default Mixin.create(Scrolling, (_dec = on("didInsertElement"), _dec2 = on("willDestroyElement"), (_obj = {
  scrolled() {
    return this.eyeline?.update();
  },
  loadMoreUnlessFull() {
    if (this.screenNotFull()) {
      this.send("loadMore");
    }
  },
  _bindEyeline() {
    const eyeline = Eyeline.create({
      selector: `${this.eyelineSelector}:last`
    });
    this.set("eyeline", eyeline);
    eyeline.on("sawBottom", () => this.send("loadMore"));
    eyeline.update(); // update once to consider current position

    this.bindScrolling();
  },
  _removeEyeline() {
    this.unbindScrolling();
  }
}, (_applyDecoratedDescriptor(_obj, "_bindEyeline", [_dec], Object.getOwnPropertyDescriptor(_obj, "_bindEyeline"), _obj), _applyDecoratedDescriptor(_obj, "_removeEyeline", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_removeEyeline"), _obj)), _obj)));