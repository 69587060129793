import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _ActivationControls;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let ActivationControls = (_class = (_ActivationControls = class ActivationControls extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
  }
  get canEditEmail() {
    return this.siteSettings.enable_local_logins || this.siteSettings.email_editable;
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="activation-controls">
      {{#unless this.siteSettings.must_approve_users}}
        <DButton
          @action={{@sendActivationEmail}}
          @label="login.resend_title"
          @icon="envelope"
          class="btn-primary resend"
        />
      {{/unless}}

      {{#if this.canEditEmail}}
        <DButton
          @action={{@editActivationEmail}}
          @label="login.change_email"
          @icon="pencil-alt"
          class="edit-email"
        />
      {{/if}}
    </div>
  
*/
{
  "id": "HfmmpOKe",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activation-controls\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"siteSettings\",\"must_approve_users\"]]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-primary resend\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,1],\"login.resend_title\",\"envelope\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"canEditEmail\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"edit-email\"]],[[\"@action\",\"@label\",\"@icon\"],[[30,2],\"login.change_email\",\"pencil-alt\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@sendActivationEmail\",\"@editActivationEmail\"],false,[\"unless\",\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/activation-controls.js",
  "scope": () => [DButton],
  "isStrictMode": true
}), _ActivationControls), _ActivationControls), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { ActivationControls as default };