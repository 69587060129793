import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="sidebar-sections sidebar-sections-anonymous">
  <Sidebar::Anonymous::CustomSections @collapsable={{@collapsableSections}} />
  <Sidebar::Anonymous::CategoriesSection
    @collapsable={{@collapsableSections}}
  />

  {{#if this.siteSettings.tagging_enabled}}
    <Sidebar::Anonymous::TagsSection @collapsable={{@collapsableSections}} />
  {{/if}}
</div>
*/
{
  "id": "714TLqzO",
  "block": "[[[10,0],[14,0,\"sidebar-sections sidebar-sections-anonymous\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@collapsable\"],[[30,1]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@collapsableSections\"],false,[\"sidebar/anonymous/custom-sections\",\"sidebar/anonymous/categories-section\",\"if\",\"sidebar/anonymous/tags-section\"]]",
  "moduleName": "discourse/components/sidebar/anonymous/sections.hbs",
  "isStrictMode": false
});