import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _DesktopNotificationsConfig;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import i18n from "discourse-common/helpers/i18n";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let DesktopNotificationsConfig = (_class = (_DesktopNotificationsConfig = class DesktopNotificationsConfig extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "desktopNotifications", _descriptor, this);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div class="controls">
      {{#if this.desktopNotifications.isNotSupported}}
        <DButton
          @icon="bell-slash"
          @label="user.desktop_notifications.not_supported"
          @disabled="true"
          class="btn-default"
        />
      {{/if}}
      {{#if this.desktopNotifications.isDeniedPermission}}
        <DButton
          @icon="bell-slash"
          @label="user.desktop_notifications.perm_denied_btn"
          @disabled="true"
          class="btn-default"
        />
        {{i18n "user.desktop_notifications.perm_denied_expl"}}
      {{else}}
        {{#if this.desktopNotifications.isSubscribed}}
          <DButton
            @icon="far-bell-slash"
            @label="user.desktop_notifications.disable"
            @action={{this.desktopNotifications.disable}}
            class="btn-default"
          />
        {{else}}
          <DButton
            @icon="far-bell"
            @label="user.desktop_notifications.enable"
            @action={{this.desktopNotifications.enable}}
            class="btn-default"
          />
        {{/if}}
      {{/if}}
    </div>
  
*/
{
  "id": "5qU/c2rl",
  "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"desktopNotifications\",\"isNotSupported\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-default\"]],[[\"@icon\",\"@label\",\"@disabled\"],[\"bell-slash\",\"user.desktop_notifications.not_supported\",\"true\"]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"desktopNotifications\",\"isDeniedPermission\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-default\"]],[[\"@icon\",\"@label\",\"@disabled\"],[\"bell-slash\",\"user.desktop_notifications.perm_denied_btn\",\"true\"]],null],[1,\"\\n        \"],[1,[28,[32,1],[\"user.desktop_notifications.perm_denied_expl\"],null]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"desktopNotifications\",\"isSubscribed\"]],[[[1,\"          \"],[8,[32,0],[[24,0,\"btn-default\"]],[[\"@icon\",\"@label\",\"@action\"],[\"far-bell-slash\",\"user.desktop_notifications.disable\",[30,0,[\"desktopNotifications\",\"disable\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,0],[[24,0,\"btn-default\"]],[[\"@icon\",\"@label\",\"@action\"],[\"far-bell\",\"user.desktop_notifications.enable\",[30,0,[\"desktopNotifications\",\"enable\"]]]],null],[1,\"\\n\"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/desktop-notification-config.js",
  "scope": () => [DButton, i18n],
  "isStrictMode": true
}), _DesktopNotificationsConfig), _DesktopNotificationsConfig), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "desktopNotifications", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { DesktopNotificationsConfig as default };