import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './categories-section.hbs'; */
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { action } from "@ember/object";
import { service } from "@ember/service";
import SidebarCommonCategoriesSection from "discourse/components/sidebar/common/categories-section";
import SidebarEditNavigationMenuCategoriesModal from "discourse/components/sidebar/edit-navigation-menu/categories-modal";
import { hasDefaultSidebarCategories } from "discourse/lib/sidebar/helpers";
import Category from "discourse/models/category";
import { debounce } from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-section.hbs";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
import { setComponentTemplate } from "@ember/component";
export const REFRESH_COUNTS_APP_EVENT_NAME = "sidebar:refresh-categories-section-counts";
let SidebarUserCategoriesSection = (_dec = debounce(300), (_class = class SidebarUserCategoriesSection extends SidebarCommonCategoriesSection {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "appEvents", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "modal", _descriptor3, this);
    _initializerDefineProperty(this, "router", _descriptor4, this);
    this.callbackId = this.topicTrackingState.onStateChange(() => {
      this._refreshCounts();
    });
    this.appEvents.on(REFRESH_COUNTS_APP_EVENT_NAME, this, this._refreshCounts);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.topicTrackingState.offStateChange(this.callbackId);
    this.appEvents.off(REFRESH_COUNTS_APP_EVENT_NAME, this, this._refreshCounts);
  }

  // TopicTrackingState changes or plugins can trigger this function so we debounce to ensure we're not refreshing
  // unnecessarily.
  _refreshCounts() {
    this.sectionLinks.forEach(sectionLink => {
      sectionLink.refreshCounts();
    });
  }
  get categories() {
    if (this.currentUser.sidebarCategoryIds?.length > 0) {
      return Category.findByIds(this.currentUser.sidebarCategoryIds);
    } else {
      return this.topSiteCategories;
    }
  }
  get shouldDisplayDefaultConfig() {
    return this.currentUser.admin && !this.hasDefaultSidebarCategories;
  }
  get hasDefaultSidebarCategories() {
    return hasDefaultSidebarCategories(this.siteSettings);
  }
  showModal() {
    this.modal.show(SidebarEditNavigationMenuCategoriesModal);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "_refreshCounts", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_refreshCounts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "categories", [cached], Object.getOwnPropertyDescriptor(_class.prototype, "categories"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showModal", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showModal"), _class.prototype)), _class));
export { SidebarUserCategoriesSection as default };
setComponentTemplate(TEMPLATE, SidebarUserCategoriesSection);