import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './messages-section.hbs'; */
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { getOwner } from "@ember/application";
import { service } from "@ember/service";
import GroupMessageSectionLink from "discourse/lib/sidebar/user/messages-section/group-message-section-link";
import PersonalMessageSectionLink from "discourse/lib/sidebar/user/messages-section/personal-message-section-link";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./messages-section.hbs";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
import { setComponentTemplate } from "@ember/component";
export const INBOX = "inbox";
export const UNREAD = "unread";
const SENT = "sent";
export const NEW = "new";
const ARCHIVE = "archive";
export const PERSONAL_MESSAGES_INBOX_FILTERS = [INBOX, NEW, UNREAD, SENT, ARCHIVE];
export const GROUP_MESSAGES_INBOX_FILTERS = [INBOX, NEW, UNREAD, ARCHIVE];
let SidebarUserMessagesSection = (_class = class SidebarUserMessagesSection extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "appEvents", _descriptor, this);
    _initializerDefineProperty(this, "pmTopicTrackingState", _descriptor2, this);
    _initializerDefineProperty(this, "currentUser", _descriptor3, this);
    _initializerDefineProperty(this, "router", _descriptor4, this);
    this.appEvents.on("page:changed", this, this._refreshSectionLinksDisplayState);
    this._pmTopicTrackingStateKey = "messages-section";
    this.pmTopicTrackingState.onStateChange(this._pmTopicTrackingStateKey, this._refreshSectionLinkCounts);
  }
  _refreshSectionLinkCounts() {
    for (const sectionLink of this.allSectionLinks) {
      sectionLink.refreshCount();
    }
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.appEvents.off("page:changed", this, this._refreshSectionLinksDisplayState);
    this.pmTopicTrackingState.offStateChange(this._pmTopicTrackingStateKey, this._refreshSectionLinkCounts);
  }
  _refreshSectionLinksDisplayState() {
    const currentRouteName = this.router.currentRoute.name;
    const currentRouteParentName = this.router.currentRoute.parent.name;
    const currentRouteParentParams = this.router.currentRoute.parent.params;
    if (!currentRouteParentName.includes("userPrivateMessages") && currentRouteParentName !== "topic") {
      for (const sectionLink of this.allSectionLinks) {
        sectionLink.collapse();
      }
    } else {
      const attrs = {
        currentRouteName,
        currentRouteParentParams
      };
      if (currentRouteParentName === "topic") {
        const topicController = getOwner(this).lookup("controller:topic");
        if (topicController.model.isPrivateMessage) {
          attrs.privateMessageTopic = topicController.model;
        }
      }
      for (const sectionLink of this.allSectionLinks) {
        sectionLink.pageChanged(attrs);
      }
    }
  }
  get personalMessagesSectionLinks() {
    const links = [];
    PERSONAL_MESSAGES_INBOX_FILTERS.forEach(type => {
      links.push(new PersonalMessageSectionLink({
        currentUser: this.currentUser,
        type,
        pmTopicTrackingState: this.pmTopicTrackingState
      }));
    });
    return links;
  }
  get groupMessagesSectionLinks() {
    const links = [];
    this.currentUser.groupsWithMessages.sort((a, b) => a.name.localeCompare(b.name)).forEach(group => {
      GROUP_MESSAGES_INBOX_FILTERS.forEach(groupMessageLink => {
        links.push(new GroupMessageSectionLink({
          group,
          type: groupMessageLink,
          currentUser: this.currentUser,
          pmTopicTrackingState: this.pmTopicTrackingState
        }));
      });
    });
    return links;
  }
  get allSectionLinks() {
    return [...this.groupMessagesSectionLinks, ...this.personalMessagesSectionLinks];
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pmTopicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "_refreshSectionLinkCounts", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "_refreshSectionLinkCounts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "personalMessagesSectionLinks", [cached], Object.getOwnPropertyDescriptor(_class.prototype, "personalMessagesSectionLinks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "groupMessagesSectionLinks", [cached], Object.getOwnPropertyDescriptor(_class.prototype, "groupMessagesSectionLinks"), _class.prototype)), _class);
export { SidebarUserMessagesSection as default };
setComponentTemplate(TEMPLATE, SidebarUserMessagesSection);