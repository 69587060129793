import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _ModalContainer;
import Component from "@glimmer/component";
import { array } from "@ember/helper";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import { service } from "@ember/service";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let ModalContainer = (_class = (_ModalContainer = class ModalContainer extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "modal", _descriptor, this);
  }
  closeModal(data1) {
    this.modal.close(data1);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <div
      {{didInsert this.modal.setContainerElement}}
      class="modal-container"
    ></div>

    {{#if this.modal.activeModal}}
      {{#each (array this.modal.activeModal) as |activeModal|}}
        {{! #each ensures that the activeModal component/model are updated atomically }}
        <activeModal.component
          @model={{activeModal.opts.model}}
          @closeModal={{this.closeModal}}
        />
      {{/each}}
    {{/if}}
  
*/
{
  "id": "RMgTCABg",
  "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"modal-container\"],[4,[32,0],[[30,0,[\"modal\",\"setContainerElement\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"modal\",\"activeModal\"]],[[[42,[28,[31,2],[[28,[31,2],[[28,[32,1],[[30,0,[\"modal\",\"activeModal\"]]],null]],null]],null],null,[[[1,\"        \"],[8,[30,1,[\"component\"]],null,[[\"@model\",\"@closeModal\"],[[30,1,[\"opts\",\"model\"]],[30,0,[\"closeModal\"]]]],null],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"  \"]],[\"activeModal\"],false,[\"if\",\"each\",\"-track-array\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/modal-container.js",
  "scope": () => [didInsert, array],
  "isStrictMode": true
}), _ModalContainer), _ModalContainer), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "closeModal", [action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class);
export { ModalContainer as default };