import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _Icons;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { eq, not, or } from "truth-helpers";
import DAG from "discourse/lib/dag";
import getURL from "discourse-common/lib/get-url";
import Dropdown from "./dropdown";
import UserDropdown from "./user-dropdown";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let headerIcons;
resetHeaderIcons();
function resetHeaderIcons() {
  headerIcons = new DAG({
    defaultPosition: {
      before: "search"
    }
  });
  headerIcons.add("search");
  headerIcons.add("hamburger", undefined, {
    after: "search"
  });
  headerIcons.add("user-menu", undefined, {
    after: "hamburger"
  });
}
export function headerIconsDAG() {
  return headerIcons;
}
export function clearExtraHeaderIcons() {
  resetHeaderIcons();
}
let Icons = (_class = (_Icons = class Icons extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "header", _descriptor3, this);
    _initializerDefineProperty(this, "search", _descriptor4, this);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <ul class="icons d-header-icons">
      {{#each (headerIcons.resolve) as |entry|}}
        {{#if (eq entry.key "search")}}
          <Dropdown
            @title="search.title"
            @icon="search"
            @iconId={{@searchButtonId}}
            @onClick={{@toggleSearchMenu}}
            @active={{this.search.visible}}
            @href={{getURL "/search"}}
            @className="search-dropdown"
            @targetSelector=".search-menu-panel"
          />
        {{else if (eq entry.key "hamburger")}}
          {{#if (or (not @sidebarEnabled) this.site.mobileView)}}
            <Dropdown
              @title="hamburger_menu"
              @icon="bars"
              @iconId="toggle-hamburger-menu"
              @active={{this.header.hamburgerVisible}}
              @onClick={{@toggleHamburger}}
              @className="hamburger-dropdown"
            />
          {{/if}}
        {{else if (eq entry.key "user-menu")}}
          {{#if this.currentUser}}
            <UserDropdown
              @active={{this.header.userVisible}}
              @toggleUserMenu={{@toggleUserMenu}}
            />
          {{/if}}
        {{else if entry.value}}
          <entry.value />
        {{/if}}
      {{/each}}
    </ul>
  
*/
{
  "id": "5c17ga1L",
  "block": "[[[1,\"\\n    \"],[10,\"ul\"],[14,0,\"icons d-header-icons\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[28,[32,0,[\"resolve\"]],null,null]],null]],null],null,[[[41,[28,[32,1],[[30,1,[\"key\"]],\"search\"],null],[[[1,\"          \"],[8,[32,2],null,[[\"@title\",\"@icon\",\"@iconId\",\"@onClick\",\"@active\",\"@href\",\"@className\",\"@targetSelector\"],[\"search.title\",\"search\",[30,2],[30,3],[30,0,[\"search\",\"visible\"]],[28,[32,3],[\"/search\"],null],\"search-dropdown\",\".search-menu-panel\"]],null],[1,\"\\n\"]],[]],[[[41,[28,[32,1],[[30,1,[\"key\"]],\"hamburger\"],null],[[[41,[28,[32,4],[[28,[32,5],[[30,4]],null],[30,0,[\"site\",\"mobileView\"]]],null],[[[1,\"            \"],[8,[32,2],null,[[\"@title\",\"@icon\",\"@iconId\",\"@active\",\"@onClick\",\"@className\"],[\"hamburger_menu\",\"bars\",\"toggle-hamburger-menu\",[30,0,[\"header\",\"hamburgerVisible\"]],[30,5],\"hamburger-dropdown\"]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[28,[32,1],[[30,1,[\"key\"]],\"user-menu\"],null],[[[41,[30,0,[\"currentUser\"]],[[[1,\"            \"],[8,[32,6],null,[[\"@active\",\"@toggleUserMenu\"],[[30,0,[\"header\",\"userVisible\"]],[30,6]]],null],[1,\"\\n\"]],[]],null]],[]],[[[41,[30,1,[\"value\"]],[[[1,\"          \"],[8,[30,1,[\"value\"]],null,null,null],[1,\"\\n        \"]],[]],null]],[]]]],[]]]],[]]]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"entry\",\"@searchButtonId\",\"@toggleSearchMenu\",\"@sidebarEnabled\",\"@toggleHamburger\",\"@toggleUserMenu\"],false,[\"each\",\"-track-array\",\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/icons.js",
  "scope": () => [headerIcons, eq, Dropdown, getURL, or, not, UserDropdown],
  "isStrictMode": true
}), _Icons), _Icons), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "header", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { Icons as default };