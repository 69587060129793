import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Sidebar::Section
  @sectionName="categories"
  @headerLinkText={{i18n "sidebar.sections.categories.header_link_text"}}
  @collapsable={{@collapsable}}
>

  {{#each this.sectionLinks as |sectionLink|}}
    <Sidebar::SectionLink
      @route={{sectionLink.route}}
      @title={{sectionLink.title}}
      @content={{sectionLink.text}}
      @currentWhen={{sectionLink.currentWhen}}
      @model={{sectionLink.model}}
      @prefixType={{sectionLink.prefixType}}
      @prefixValue={{sectionLink.prefixValue}}
      @prefixColor={{sectionLink.prefixColor}}
      data-category-id={{sectionLink.category.id}}
    />
  {{/each}}

  <Sidebar::Common::AllCategoriesSectionLink />
</Sidebar::Section>
*/
{
  "id": "ttiCJxGR",
  "block": "[[[8,[39,0],null,[[\"@sectionName\",\"@headerLinkText\",\"@collapsable\"],[\"categories\",[28,[37,1],[\"sidebar.sections.categories.header_link_text\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"sectionLinks\"]]],null]],null],null,[[[1,\"    \"],[8,[39,4],[[16,\"data-category-id\",[30,2,[\"category\",\"id\"]]]],[[\"@route\",\"@title\",\"@content\",\"@currentWhen\",\"@model\",\"@prefixType\",\"@prefixValue\",\"@prefixColor\"],[[30,2,[\"route\"]],[30,2,[\"title\"]],[30,2,[\"text\"]],[30,2,[\"currentWhen\"]],[30,2,[\"model\"]],[30,2,[\"prefixType\"]],[30,2,[\"prefixValue\"]],[30,2,[\"prefixColor\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n  \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]]]]]],[\"@collapsable\",\"sectionLink\"],false,[\"sidebar/section\",\"i18n\",\"each\",\"-track-array\",\"sidebar/section-link\",\"sidebar/common/all-categories-section-link\"]]",
  "moduleName": "discourse/components/sidebar/anonymous/categories-section.hbs",
  "isStrictMode": false
});