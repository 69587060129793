import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{this.i18nCount}}
*/
{
  "id": "xtZTkig+",
  "block": "[[[1,[30,0,[\"i18nCount\"]]]],[],false,[]]",
  "moduleName": "discourse/components/count-i18n.hbs",
  "isStrictMode": false
});