import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _NotificationConsentBanner;
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { keyValueStore as pushNotificationKeyValueStore } from "discourse/lib/push-notifications";
import i18n from "discourse-common/helpers/i18n";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
const userDismissedPromptKey = "dismissed-prompt";
let NotificationConsentBanner = (_class = (_NotificationConsentBanner = class NotificationConsentBanner extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "capabilities", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "desktopNotifications", _descriptor3, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor4, this);
    _initializerDefineProperty(this, "bannerDismissed", _descriptor5, this);
    this.bannerDismissed = pushNotificationKeyValueStore.getItem(userDismissedPromptKey);
  }
  setBannerDismissed(value1) {
    pushNotificationKeyValueStore.setItem(userDismissedPromptKey, value1);
    this.bannerDismissed = pushNotificationKeyValueStore.getItem(userDismissedPromptKey);
  }
  get showNotificationPromptBanner() {
    return this.siteSettings.push_notifications_prompt && !this.desktopNotifications.isNotSupported && this.currentUser && this.capabilities.isPwa && Notification.permission !== "denied" && Notification.permission !== "granted" && !this.desktopNotifications.isEnabled && !this.bannerDismissed;
  }
  turnon() {
    this.desktopNotifications.enable();
    this.setBannerDismissed(true);
  }
  dismiss() {
    this.setBannerDismissed(false);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.showNotificationPromptBanner}}
      <div class="row">
        <div class="consent_banner alert alert-info">
          <span>
            {{i18n "user.desktop_notifications.consent_prompt"}}
            <DButton
              @display="link"
              @action={{this.turnon}}
              @label="user.desktop_notifications.enable"
            />
          </span>
          <DButton
            @icon="times"
            @action={{this.dismiss}}
            @title="banner.close"
            class="btn-transparent close"
          />
        </div>
      </div>
    {{/if}}
  
*/
{
  "id": "A+Yy3kFq",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"showNotificationPromptBanner\"]],[[[1,\"      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"consent_banner alert alert-info\"],[12],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[1,[28,[32,0],[\"user.desktop_notifications.consent_prompt\"],null]],[1,\"\\n            \"],[8,[32,1],null,[[\"@display\",\"@action\",\"@label\"],[\"link\",[30,0,[\"turnon\"]],\"user.desktop_notifications.enable\"]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[32,1],[[24,0,\"btn-transparent close\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[30,0,[\"dismiss\"]],\"banner.close\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/notification-consent-banner.js",
  "scope": () => [i18n, DButton],
  "isStrictMode": true
}), _NotificationConsentBanner), _NotificationConsentBanner), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "capabilities", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "desktopNotifications", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "bannerDismissed", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "turnon", [action], Object.getOwnPropertyDescriptor(_class.prototype, "turnon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismiss", [action], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype)), _class);
export { NotificationConsentBanner as default };