import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _SidebarToggle;
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import concatClass from "discourse/helpers/concat-class";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let SidebarToggle = (_class = (_SidebarToggle = class SidebarToggle extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
  }
  toggleWithBlur(e1) {
    this.args.toggleHamburger();
    // remove the focus of the header dropdown button after clicking
    e1.target.tagName.toLowerCase() === "button" ? e1.target.blur() : e1.target.closest("button").blur();
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <span class="header-sidebar-toggle">
      <button
        title={{i18n "sidebar.title"}}
        class={{concatClass
          "btn btn-flat btn-sidebar-toggle no-text btn-icon"
          (if this.site.narrowDesktopView "narrow-desktop")
        }}
        aria-expanded={{if @showSidebar "true" "false"}}
        aria-controls="d-sidebar"
        {{on "click" this.toggleWithBlur}}
      >
        {{icon "bars"}}
      </button>
    </span>
  
*/
{
  "id": "NUQeGcpB",
  "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"header-sidebar-toggle\"],[12],[1,\"\\n      \"],[11,\"button\"],[16,\"title\",[28,[32,0],[\"sidebar.title\"],null]],[16,0,[28,[32,1],[\"btn btn-flat btn-sidebar-toggle no-text btn-icon\",[52,[30,0,[\"site\",\"narrowDesktopView\"]],\"narrow-desktop\"]],null]],[16,\"aria-expanded\",[52,[30,1],\"true\",\"false\"]],[24,\"aria-controls\",\"d-sidebar\"],[4,[32,2],[\"click\",[30,0,[\"toggleWithBlur\"]]],null],[12],[1,\"\\n        \"],[1,[28,[32,3],[\"bars\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@showSidebar\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/sidebar-toggle.js",
  "scope": () => [i18n, concatClass, on, icon],
  "isStrictMode": true
}), _SidebarToggle), _SidebarToggle), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "toggleWithBlur", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleWithBlur"), _class.prototype)), _class);
export { SidebarToggle as default };