import { LinkTo } from "@ember/routing";
import dIcon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
import templateOnly from "@ember/component/template-only";
export default setComponentTemplate(createTemplateFactory(
/*
  
  <LinkTo class="btn btn-flat back-button" @route={{@route}}>
    {{dIcon "chevron-left"}}
    {{i18n "back_button"}}
  </LinkTo>

*/
{
  "id": "l4I61lFl",
  "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"btn btn-flat back-button\"]],[[\"@route\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[32,1],[\"chevron-left\"],null]],[1,\"\\n    \"],[1,[28,[32,2],[\"back_button\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@route\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/back-button.js",
  "scope": () => [LinkTo, dIcon, i18n],
  "isStrictMode": true
}), templateOnly());