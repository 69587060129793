import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Sidebar::SectionLink
  @linkName="all-tags"
  @content={{i18n "sidebar.all_tags"}}
  @route="tags"
  @prefixType="icon"
  @prefixValue="list"
/>
*/
{
  "id": "oRRPUuRf",
  "block": "[[[8,[39,0],null,[[\"@linkName\",\"@content\",\"@route\",\"@prefixType\",\"@prefixValue\"],[\"all-tags\",[28,[37,1],[\"sidebar.all_tags\"],null],\"tags\",\"icon\",\"list\"]],null]],[],false,[\"sidebar/section-link\",\"i18n\"]]",
  "moduleName": "discourse/components/sidebar/common/all-tags-section-link.hbs",
  "isStrictMode": false
});