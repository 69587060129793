import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./section.hbs";
import { setComponentTemplate } from "@ember/component";
let SidebarSection = (_class = class SidebarSection extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "keyValueStore", _descriptor, this);
    _initializerDefineProperty(this, "sidebarState", _descriptor2, this);
    _initializerDefineProperty(this, "collapsedSections", _descriptor3, this);
    _defineProperty(this, "sidebarSectionContentID", `sidebar-section-content-${this.args.sectionName}`);
    _defineProperty(this, "collapsedSidebarSectionKey", `sidebar-section-${this.args.sectionName}-collapsed`);
  }
  get isCollapsed() {
    if (!this.args.collapsable) {
      return false;
    }
    if (this.keyValueStore.getItem(this.collapsedSidebarSectionKey) === undefined) {
      return this.args.collapsedByDefault;
    }
    return this.keyValueStore.getItem(this.collapsedSidebarSectionKey) === "true";
  }
  setExpandedState() {
    if (this.isCollapsed) {
      this.sidebarState.collapseSection(this.args.sectionName);
    } else {
      this.sidebarState.expandSection(this.args.sectionName);
    }
  }
  get displaySectionContent() {
    return !this.collapsedSections.includes(this.collapsedSidebarSectionKey);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.args.willDestroy?.();
  }
  toggleSectionDisplay() {
    if (this.displaySectionContent) {
      this.sidebarState.collapseSection(this.args.sectionName);
    } else {
      this.sidebarState.expandSection(this.args.sectionName);
    }

    // remove focus from the toggle, but only on click
    if (!event.key) {
      document.activeElement.blur();
    }
  }
  handleMultipleHeaderActions(id) {
    this.args.headerActions.find(headerAction => headerAction.id === id).action();
  }
  get headerCaretIcon() {
    return this.displaySectionContent ? "angle-down" : "angle-right";
  }
  get isSingleHeaderAction() {
    return this.args.headerActions?.length === 1;
  }
  get isMultipleHeaderActions() {
    return this.args.headerActions?.length > 1;
  }
  get displaySection() {
    if (this.args.displaySection === undefined) {
      return true;
    }
    return this.args.displaySection;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keyValueStore", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "collapsedSections", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.sidebarState.collapsedSections;
  }
}), _applyDecoratedDescriptor(_class.prototype, "setExpandedState", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "setExpandedState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSectionDisplay", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSectionDisplay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMultipleHeaderActions", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMultipleHeaderActions"), _class.prototype)), _class);
export { SidebarSection as default };
setComponentTemplate(TEMPLATE, SidebarSection);