import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="sidebar-custom-sections">
  {{#each this.sections as |section|}}
    <Sidebar::Common::CustomSection
      @sectionData={{section}}
      @collapsable={{@collapsable}}
    />
  {{/each}}
</div>
*/
{
  "id": "XOSm4xre",
  "block": "[[[10,0],[14,0,\"sidebar-custom-sections\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"sections\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@sectionData\",\"@collapsable\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[1]],null],[13]],[\"section\",\"@collapsable\"],false,[\"each\",\"-track-array\",\"sidebar/common/custom-section\"]]",
  "moduleName": "discourse/components/sidebar/common/custom-sections.hbs",
  "isStrictMode": false
});