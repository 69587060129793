import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <a
  role="tab"
  class={{this.classNames}}
  id={{this.id}}
  tabindex={{this.tabIndex}}
  title={{@tab.title}}
  aria-selected={{if this.isActive "true" "false"}}
  aria-controls={{this.ariaControls}}
  data-tab-number={{@tab.position}}
  href={{@tab.linkWhenActive}}
  {{on "click" @onTabClick}}
  {{on "keydown" @onTabClick}}
>
  {{d-icon @tab.icon}}
  {{#if @tab.count}}
    <span aria-hidden="true" class="badge-notification">{{@tab.count}}</span>
  {{/if}}
  {{yield}}
</a>
*/
{
  "id": "MNxA/ZHg",
  "block": "[[[11,3],[24,\"role\",\"tab\"],[16,0,[30,0,[\"classNames\"]]],[16,1,[30,0,[\"id\"]]],[16,\"tabindex\",[30,0,[\"tabIndex\"]]],[16,\"title\",[30,1,[\"title\"]]],[16,\"aria-selected\",[52,[30,0,[\"isActive\"]],\"true\",\"false\"]],[16,\"aria-controls\",[30,0,[\"ariaControls\"]]],[16,\"data-tab-number\",[30,1,[\"position\"]]],[16,6,[30,1,[\"linkWhenActive\"]]],[4,[38,1],[\"click\",[30,2]],null],[4,[38,1],[\"keydown\",[30,2]],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,1,[\"icon\"]]],null]],[1,\"\\n\"],[41,[30,1,[\"count\"]],[[[1,\"    \"],[10,1],[14,\"aria-hidden\",\"true\"],[14,0,\"badge-notification\"],[12],[1,[30,1,[\"count\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,3,null],[1,\"\\n\"],[13]],[\"@tab\",\"@onTabClick\",\"&default\"],false,[\"if\",\"on\",\"d-icon\",\"yield\"]]",
  "moduleName": "discourse/components/user-menu/menu-tab.hbs",
  "isStrictMode": false
});