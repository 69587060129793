import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _FilterNoResulsts;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import i18n from "discourse-common/helpers/i18n";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let FilterNoResulsts = (_class = (_FilterNoResulsts = class FilterNoResulsts extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "sidebarState", _descriptor, this);
  }
  /**
  * Component is rendered when panel is filtreable
  * Visibility is additionally controlled by CSS rule `.sidebar-section-wrapper + .sidebar-no-results`
  */
  get shouldDisplay() {
    return this.sidebarState.currentPanel.filterable;
  }
  get noResultsDescription() {
    const params1 = {
      filter: this.sidebarState.filter,
      settings_filter_url: getURL(`/admin/site_settings/category/all_results?filter=${this.sidebarState.filter}`),
      user_list_filter_url: getURL(`/admin/users/list/active?username=${this.sidebarState.filter}`)
    };
    return htmlSafe(I18n.t("sidebar.no_results.description", params1));
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.shouldDisplay}}
      <div class="sidebar-no-results">
        <h4 class="sidebar-no-results__title">{{i18n
            "sidebar.no_results.title"
          }}</h4>
        <p
          class="sidebar-no-results__description"
        >{{this.noResultsDescription}}</p>
      </div>
    {{/if}}
  
*/
{
  "id": "AZeIaHsD",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[10,0],[14,0,\"sidebar-no-results\"],[12],[1,\"\\n        \"],[10,\"h4\"],[14,0,\"sidebar-no-results__title\"],[12],[1,[28,[32,0],[\"sidebar.no_results.title\"],null]],[13],[1,\"\\n        \"],[10,2],[14,0,\"sidebar-no-results__description\"],[12],[1,[30,0,[\"noResultsDescription\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/filter-no-results.js",
  "scope": () => [i18n],
  "isStrictMode": true
}), _FilterNoResulsts), _FilterNoResulsts), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { FilterNoResulsts as default };