import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="control-group form-template-field" data-field-type="input">
  {{#if @attributes.label}}
    <label class="form-template-field__label">
      {{@attributes.label}}
      {{#if @validations.required}}
        {{d-icon "asterisk" class="form-template-field__required-indicator"}}
      {{/if}}
    </label>
  {{/if}}

  {{#if @attributes.description}}
    <span class="form-template-field__description">
      {{html-safe @attributes.description}}
    </span>
  {{/if}}

  <Input
    name={{@id}}
    class="form-template-field__input"
    @value={{@value}}
    @type={{if @validations.type @validations.type "text"}}
    placeholder={{@attributes.placeholder}}
    required={{if @validations.required "required" ""}}
    pattern={{@validations.pattern}}
    minlength={{@validations.minimum}}
    maxlength={{@validations.maximum}}
  />
</div>
*/
{
  "id": "QDj+yBnh",
  "block": "[[[10,0],[14,0,\"control-group form-template-field\"],[14,\"data-field-type\",\"input\"],[12],[1,\"\\n\"],[41,[30,1,[\"label\"]],[[[1,\"    \"],[10,\"label\"],[14,0,\"form-template-field__label\"],[12],[1,\"\\n      \"],[1,[30,1,[\"label\"]]],[1,\"\\n\"],[41,[30,2,[\"required\"]],[[[1,\"        \"],[1,[28,[35,1],[\"asterisk\"],[[\"class\"],[\"form-template-field__required-indicator\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"description\"]],[[[1,\"    \"],[10,1],[14,0,\"form-template-field__description\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,1,[\"description\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,3],[[16,3,[30,3]],[24,0,\"form-template-field__input\"],[16,\"placeholder\",[30,1,[\"placeholder\"]]],[16,\"required\",[52,[30,2,[\"required\"]],\"required\",\"\"]],[16,\"pattern\",[30,2,[\"pattern\"]]],[16,\"minlength\",[30,2,[\"minimum\"]]],[16,\"maxlength\",[30,2,[\"maximum\"]]]],[[\"@value\",\"@type\"],[[30,4],[52,[30,2,[\"type\"]],[30,2,[\"type\"]],\"text\"]]],null],[1,\"\\n\"],[13]],[\"@attributes\",\"@validations\",\"@id\",\"@value\"],false,[\"if\",\"d-icon\",\"html-safe\",\"input\"]]",
  "moduleName": "discourse/components/form-template-field/input.hbs",
  "isStrictMode": false
});