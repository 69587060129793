import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="sidebar-section-message-wrapper sidebar-row">
  <div class="sidebar-section-message">
    {{yield}}
  </div>
</div>
*/
{
  "id": "cM0n1q7u",
  "block": "[[[10,0],[14,0,\"sidebar-section-message-wrapper sidebar-row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"sidebar-section-message\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
  "moduleName": "discourse/components/sidebar/section-message.hbs",
  "isStrictMode": false
});