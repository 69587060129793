import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _AdminHeader;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { ADMIN_PANEL } from "discourse/lib/sidebar/panels";
import BackToForum from "./back-to-forum";
import Filter from "./filter";
import ToggleAllSections from "./toggle-all-sections";
import { createTemplateFactory } from "@ember/template-factory";
import { setComponentTemplate } from "@ember/component";
let AdminHeader = (_class = (_AdminHeader = class AdminHeader extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "sidebarState", _descriptor, this);
  }
  get shouldDisplay() {
    return this.sidebarState.isCurrentPanel(ADMIN_PANEL);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.shouldDisplay}}
      <div class="sidebar-admin-header">
        <div class="sidebar-admin-header__row">
          <BackToForum />
          <ToggleAllSections @sections={{@sections}} />
        </div>
        <div class="sidebar-admin-header__row">
          <Filter />
        </div>
      </div>
    {{/if}}
  
*/
{
  "id": "tZSSO1tf",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[10,0],[14,0,\"sidebar-admin-header\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"sidebar-admin-header__row\"],[12],[1,\"\\n          \"],[8,[32,0],null,null,null],[1,\"\\n          \"],[8,[32,1],null,[[\"@sections\"],[[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"sidebar-admin-header__row\"],[12],[1,\"\\n          \"],[8,[32,2],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@sections\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/admin-header.js",
  "scope": () => [BackToForum, ToggleAllSections, Filter],
  "isStrictMode": true
}), _AdminHeader), _AdminHeader), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { AdminHeader as default };