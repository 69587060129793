import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Sidebar::SectionLink
  @linkName="all-categories"
  @content={{i18n "sidebar.all_categories"}}
  @route="discovery.categories"
  @prefixType="icon"
  @prefixValue="sidebar.all_categories"
/>
*/
{
  "id": "+VQvn+Is",
  "block": "[[[8,[39,0],null,[[\"@linkName\",\"@content\",\"@route\",\"@prefixType\",\"@prefixValue\"],[\"all-categories\",[28,[37,1],[\"sidebar.all_categories\"],null],\"discovery.categories\",\"icon\",\"sidebar.all_categories\"]],null]],[],false,[\"sidebar/section-link\",\"i18n\"]]",
  "moduleName": "discourse/components/sidebar/common/all-categories-section-link.hbs",
  "isStrictMode": false
});