import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each @buttons as |button|}}
  <DButton
    @action={{fn this.switchPanel button}}
    @icon={{button.switchButtonIcon}}
    @disabled={{this.isSwitching}}
    @translatedLabel={{button.switchButtonLabel}}
    data-key={{button.key}}
    class="btn-default sidebar__panel-switch-button"
  />
{{/each}}
*/
{
  "id": "SNRLx6il",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[8,[39,2],[[16,\"data-key\",[30,2,[\"key\"]]],[24,0,\"btn-default sidebar__panel-switch-button\"]],[[\"@action\",\"@icon\",\"@disabled\",\"@translatedLabel\"],[[28,[37,3],[[30,0,[\"switchPanel\"]],[30,2]],null],[30,2,[\"switchButtonIcon\"]],[30,0,[\"isSwitching\"]],[30,2,[\"switchButtonLabel\"]]]],null],[1,\"\\n\"]],[2]],null]],[\"@buttons\",\"button\"],false,[\"each\",\"-track-array\",\"d-button\",\"fn\"]]",
  "moduleName": "discourse/components/sidebar/switch-panel-buttons.hbs",
  "isStrictMode": false
});