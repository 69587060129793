import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './section-form-link.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import discourseLater from "discourse-common/lib/later";
import TEMPLATE from "./section-form-link.hbs";
import { setComponentTemplate } from "@ember/component";
let SectionFormLink = (_class = class SectionFormLink extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
    _initializerDefineProperty(this, "dragCssClass", _descriptor2, this);
    _defineProperty(this, "dragCount", 0);
  }
  isAboveElement(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const domRect = target.getBoundingClientRect();
    return event.offsetY < domRect.height / 2;
  }
  dragHasStarted(event) {
    event.dataTransfer.effectAllowed = "move";
    this.args.setDraggedLinkCallback(this.args.link);
    this.dragCssClass = "dragging";
  }
  dragOver(event) {
    event.preventDefault();
    if (this.dragCssClass !== "dragging") {
      if (this.isAboveElement(event)) {
        this.dragCssClass = "drag-above";
      } else {
        this.dragCssClass = "drag-below";
      }
    }
  }
  dragEnter() {
    this.dragCount++;
  }
  dragLeave() {
    this.dragCount--;
    if (this.dragCount === 0 && (this.dragCssClass === "drag-above" || this.dragCssClass === "drag-below")) {
      discourseLater(() => {
        this.dragCssClass = null;
      }, 10);
    }
  }
  dropItem(event) {
    event.stopPropagation();
    this.dragCount = 0;
    this.args.reorderCallback(this.args.link, this.isAboveElement(event));
    this.dragCssClass = null;
  }
  dragEnd() {
    this.dragCount = 0;
    this.dragCssClass = null;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dragCssClass", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "dragHasStarted", [action], Object.getOwnPropertyDescriptor(_class.prototype, "dragHasStarted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOver", [action], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEnter", [action], Object.getOwnPropertyDescriptor(_class.prototype, "dragEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragLeave", [action], Object.getOwnPropertyDescriptor(_class.prototype, "dragLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropItem", [action], Object.getOwnPropertyDescriptor(_class.prototype, "dropItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEnd", [action], Object.getOwnPropertyDescriptor(_class.prototype, "dragEnd"), _class.prototype)), _class);
export { SectionFormLink as default };
setComponentTemplate(TEMPLATE, SectionFormLink);