import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './api-sections.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import TEMPLATE from "./api-sections.hbs";
import { setComponentTemplate } from "@ember/component";
let SidebarApiSections = (_class = class SidebarApiSections extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "sidebarState", _descriptor, this);
  }
  get sections() {
    if (this.sidebarState.combinedMode) {
      return this.sidebarState.panels.filter(panel => !panel.hidden).map(panel => panel.sections).flat();
    } else {
      return this.sidebarState.currentPanel.sections;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { SidebarApiSections as default };
setComponentTemplate(TEMPLATE, SidebarApiSections);