import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './api-section.hbs'; */
import Component from "@glimmer/component";
import { getOwner, setOwner } from "@ember/application";
import { service } from "@ember/service";
import TEMPLATE from "./api-section.hbs";
import { setComponentTemplate } from "@ember/component";
let SidebarApiSection = (_class = class SidebarApiSection extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "sidebarState", _descriptor, this);
    this.section = new this.args.sectionConfig();
    setOwner(this.section, getOwner(this));
  }
  get shouldDisplay() {
    if (!this.sidebarState.currentPanel.filterable) {
      return true;
    }
    return this.sidebarState.filter.length === 0 || this.filteredLinks.length > 0;
  }
  get filteredLinks() {
    if (!this.sidebarState.filter) {
      return this.section.links;
    }
    if (this.section.text.toLowerCase().match(this.sidebarState.filter)) {
      return this.section.links;
    }
    return this.section.links.filter(link => {
      return link.text.toString().toLowerCase().match(this.sidebarState.filter) || link.keywords.navigation.some(keyword => keyword.match(this.sidebarState.filter));
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { SidebarApiSection as default };
setComponentTemplate(TEMPLATE, SidebarApiSection);