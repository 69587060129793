/* import __COLOCATED_TEMPLATE__ from './categories-section.hbs'; */
import SidebarCommonCategoriesSection from "discourse/components/sidebar/common/categories-section";
import Category from "discourse/models/category";
import TEMPLATE from "./categories-section.hbs";
import { setComponentTemplate } from "@ember/component";
export default class SidebarAnonymousCategoriesSection extends SidebarCommonCategoriesSection {
  constructor() {
    super(...arguments);
    if (!this.siteSettings.default_navigation_menu_categories) {
      this.shouldSortCategoriesByDefault = false;
    }
  }
  get categories() {
    if (this.siteSettings.default_navigation_menu_categories) {
      return Category.findByIds(this.siteSettings.default_navigation_menu_categories.split("|").map(categoryId => parseInt(categoryId, 10)));
    } else {
      return this.topSiteCategories;
    }
  }
}
setComponentTemplate(TEMPLATE, SidebarAnonymousCategoriesSection);