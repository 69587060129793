import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if @currentUser}}
  <Sidebar::User::Sections
    @collapsableSections={{@collapsableSections}}
    @panel={{@panel}}
  />
{{else}}
  <Sidebar::Anonymous::Sections @collapsableSections={{@collapsableSections}} />
{{/if}}
*/
{
  "id": "3Cp7p/7Z",
  "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@collapsableSections\",\"@panel\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@collapsableSections\"],[[30,2]]],null],[1,\"\\n\"]],[]]]],[\"@currentUser\",\"@collapsableSections\",\"@panel\"],false,[\"if\",\"sidebar/user/sections\",\"sidebar/anonymous/sections\"]]",
  "moduleName": "discourse/components/sidebar/sections.hbs",
  "isStrictMode": false
});