import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './tags-section.hbs'; */
import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { service } from "@ember/service";
import TagSectionLink from "discourse/lib/sidebar/user/tags-section/tag-section-link";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
import TEMPLATE from "./tags-section.hbs";
import { setComponentTemplate } from "@ember/component";
let SidebarAnonymousTagsSection = (_class = class SidebarAnonymousTagsSection extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor2, this);
    _initializerDefineProperty(this, "site", _descriptor3, this);
  }
  get displaySection() {
    return this.site.anonymous_default_navigation_menu_tags?.length > 0 || this.site.navigation_menu_site_top_tags?.length > 0;
  }
  get sectionLinks() {
    return (this.site.anonymous_default_navigation_menu_tags || this.site.navigation_menu_site_top_tags).map(tag => {
      return new TagSectionLink({
        tag,
        topicTrackingState: this.topicTrackingState
      });
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "sectionLinks", [cached], Object.getOwnPropertyDescriptor(_class.prototype, "sectionLinks"), _class.prototype)), _class);
export { SidebarAnonymousTagsSection as default };
setComponentTemplate(TEMPLATE, SidebarAnonymousTagsSection);